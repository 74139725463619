// https://tanstack.com/table/v8/docs/guide/introduction
import React, { useCallback, useMemo } from 'react';
import { css } from '@emotion/react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Cell, Row, TableInstance } from 'react-table';
import SimpleBar from 'simplebar-react';
import clsx from 'clsx';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  Droppable,
} from '@hello-pangea/dnd';

export type StickyTableProps<D extends {} = {}> = {
  className?: string;
  tableClassName?: string;
  maxHeight?: number | string;
  table: TableInstance<D>;
  simpleBarProps?: SimpleBar.Props;
  overflow?: boolean;
  onClickRow?: (row: Row<D>) => void;
  renderSubRow?: (ctx: { row: Row<D> }) => React.ReactNode;
};

const SortingIcon = ({ state }: { state: 'asc' | 'desc' | 'default' }) => {
  const theme = useTheme();
  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{
        fontSize: '16px',
      }}
    >
      <ArrowDropUpIcon
        sx={{
          marginBottom: '-7px',
          color: state === 'asc' ? theme.palette.primary.main : 'text.disabled',
        }}
      />
      <ArrowDropDownIcon
        sx={{
          marginTop: '-4px',
          color:
            state === 'desc' ? theme.palette.primary.main : 'text.disabled',
        }}
      />
    </Stack>
  );
};

const StickyTable = <TData extends {} = {}>({
  className,
  tableClassName,
  maxHeight,
  table,
  simpleBarProps,
  overflow = false,
  onClickRow,
  renderSubRow,
}: StickyTableProps<TData>) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    dragging,
  } = table;
  const theme = useTheme();

  const headerElm = (
    <TableHead>
      {headerGroups.map((group, i) => (
        <TableRow {...group.getHeaderGroupProps()} key={i}>
          {group.headers.map((col) => {
            return (
              <TableCell
                {...col.getHeaderProps(col.getSortByToggleProps?.())}
                key={col.id}
                sx={{
                  // TODO: remove this hack:
                  // check for default values of react-table (150, 0, MAX_SAFE_INTEGER) and replace them with undefined
                  width: col.width === 150 ? undefined : col.width,
                  minWidth: col.minWidth === 0 ? undefined : col.minWidth,
                  maxWidth:
                    col.maxWidth === Number.MAX_SAFE_INTEGER
                      ? undefined
                      : col.maxWidth,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {(() => {
                    const th = col.render('Header');
                    if (typeof th === 'string')
                      return (
                        <Typography variant="captionSemibold">{th}</Typography>
                      );
                    return th;
                  })()}
                  {col.canSort && !col.isSorted && !col.isSortedDesc ? (
                    <SortingIcon state="default" />
                  ) : (
                    col.isSorted && (
                      <SortingIcon state={col.isSortedDesc ? 'desc' : 'asc'} />
                    )
                  )}
                </Box>
              </TableCell>
            );
          })}
        </TableRow>
      ))}
    </TableHead>
  );

  const staticRowRenderer = useCallback(
    (rows: Row<TData>[]) =>
      rows.map((row) => {
        prepareRow(row);
        return (
          <StaticRow
            key={row.id}
            row={row}
            onClick={onClickRow}
            renderSubRow={renderSubRow}
          />
        );
      }),
    [onClickRow, prepareRow, renderSubRow]
  );

  const draggableRowRenderer = useCallback(
    (rows: Row<TData>[]) =>
      rows.map((row) => {
        prepareRow(row);
        return <DraggableRow key={row.id} row={row} onClick={onClickRow} />;
      }),
    [onClickRow, prepareRow]
  );

  const tableBodies = useMemo(() => {
    if (!dragging || !dragging.enabled) {
      return (
        <TableBody {...getTableBodyProps()}>
          {staticRowRenderer(rows)}
        </TableBody>
      );
    }
    if (dragging && dragging.enabled) {
      if (!dragging.draggingRange) {
        return (
          <Droppable
            droppableId={dragging.droppableId || 'droppable'}
            type={dragging.droppableType || 'stickyTable'}
          >
            {(provided) => (
              <TableBody
                key="droppable"
                {...getTableBodyProps()}
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {draggableRowRenderer(rows)}
                {provided.placeholder}
              </TableBody>
            )}
          </Droppable>
        );
      }
      const [firstDraggingIndex, lastDraggingIndex] = dragging.draggingRange;
      return [
        { type: 'static', rows: rows.slice(0, firstDraggingIndex) },
        {
          type: 'draggable',
          rows: rows.slice(firstDraggingIndex, lastDraggingIndex + 1),
        },
        { type: 'static', rows: rows.slice(lastDraggingIndex + 1) },
      ]
        .filter((body) => body.rows.length)
        .map((body, idx) => {
          if (body.type === 'draggable') {
            return (
              <Droppable
                droppableId={dragging.droppableId || 'droppable'}
                type={dragging.droppableType || 'stickyTable'}
                key={idx + '_droppable'}
              >
                {(provided) => (
                  <TableBody
                    key="droppable"
                    {...getTableBodyProps()}
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {draggableRowRenderer(body.rows)}
                    {provided.placeholder}
                  </TableBody>
                )}
              </Droppable>
            );
          }
          return (
            <TableBody {...getTableBodyProps()} key={idx + '_static'}>
              {staticRowRenderer(body.rows)}
            </TableBody>
          );
        });
    }
    return [];
  }, [
    draggableRowRenderer,
    dragging,
    getTableBodyProps,
    rows,
    staticRowRenderer,
  ]);

  let tableElm = (
    <Table
      {...getTableProps()}
      className={clsx('StickyTable-table', tableClassName)}
      stickyHeader
      css={css`
        min-width: unset !important;
        max-width: 1808px !important;

        .StickyTableRow-even,
        .StickyTableSubRow-even {
          background: #f9fafb;

          .MuiTableCell-root {
            &:first-of-type {
              border-radius: 8px 0 0 8px;
            }

            &:last-child {
              border-radius: 0 8px 8px 0;
            }
          }
        }
        .MuiTableCell-body {
          padding: 12px 8px;
          font-size: ${theme.typography.tableRow.fontSize};
        }
        .MuiTableCell-head {
          padding: 7px 8px;
        }
        .MuiTableCell-body:first-of-type {
          padding-left: 12px;
        }
        .MuiTableCell-head:first-of-type {
          padding-left: 12px;
        }
        .MuiTableCell-body:last-of-type {
          padding-right: 12px;
        }
        .MuiTableCell-head:last-of-type {
          padding-right: 12px;
        }
      `}
      sx={{
        '.MuiTableCell-body': {
          ...theme.typography.tableRow,
        },
      }}
    >
      {headerElm}
      {tableBodies}
    </Table>
  );

  if (dragging && dragging.enabled && !dragging.withoutContext) {
    tableElm = (
      <DragDropContext
        onDragEnd={dragging.onDragEnd}
        onDragStart={dragging.onDragStart}
        onDragUpdate={dragging.onDragUpdate}
      >
        {tableElm}
      </DragDropContext>
    );
  }

  if (!overflow)
    return (
      <Box className={clsx('StickyTable-root', className)}>{tableElm}</Box>
    );
  return (
    <SimpleBar
      {...simpleBarProps}
      className={clsx(
        'StickyTable-root',
        simpleBarProps?.classNames,
        className
      )}
      style={{ ...(simpleBarProps as any)?.style, maxHeight }}
      css={css`
        min-height: 0;
        max-height: 100%;
      `}
    >
      {tableElm}
    </SimpleBar>
  );
};

interface RowProps<T extends {} = {}> {
  row: Row<T>;
  onClick?: (row: Row<T>) => void;
}

const StaticRow = <TData extends {} = {}>({
  renderSubRow,
  row,
  onClick,
}: RowProps<TData> & {
  renderSubRow: StickyTableProps<TData>['renderSubRow'];
}) => {
  const rowProps = row.getRowProps();
  const isEvenRow = (row.index + 1) % 2 === 0;
  const mainRow = (
    <TableRow
      {...rowProps}
      key="main"
      className={clsx(
        'StickyTableRow-root',
        isEvenRow ? 'StickyTableRow-even' : 'StickyTableRow-odd',
        rowProps.className
      )}
      onClick={() => onClick?.(row)}
    >
      {row.cells.map((cell) => {
        return <StaticCell key={cell.column.id} cell={cell} />;
      })}
    </TableRow>
  );
  if (!renderSubRow) return mainRow;
  return (
    <>
      {mainRow}
      <TableRow
        {...rowProps}
        key="sub"
        className={clsx(
          'StickyTableSubRow-root',
          isEvenRow ? 'StickyTableSubRow-even' : 'StickyTableSubRow-odd',
          rowProps.className
        )}
        onClick={() => onClick?.(row)}
      >
        <td colSpan={row.cells.length}>{renderSubRow({ row })}</td>
      </TableRow>
    </>
  );
};

const DraggableRow = <TData extends {} = {}>({
  row,
  onClick,
}: RowProps<TData>) => {
  const theme = useTheme();
  const rowProps = row.getRowProps();
  const isEvenRow = (row.index + 1) % 2 === 0;
  return (
    <Draggable draggableId={row.id} index={row.index}>
      {(provided, snapshot) => {
        return (
          <TableRow
            {...row.getRowProps()}
            {...provided.draggableProps}
            sx={{
              background: snapshot.isDragging
                ? theme.palette.common.white
                : null,
              boxShadow: snapshot.isDragging ? 8 : null,
              borderRadius: 1,
            }}
            className={clsx(
              'StickyTableRow-root',
              isEvenRow ? 'StickyTableRow-even' : 'StickyTableRow-odd',
              rowProps.className
            )}
            ref={provided.innerRef}
            onClick={() => onClick?.(row)}
          >
            {row.cells.map((cell, idx) => {
              return (
                <DraggableCell
                  key={cell.column.id}
                  idx={idx}
                  cell={cell}
                  draggableProvided={provided}
                  isDragOccurring={snapshot.isDragging}
                />
              );
            })}
          </TableRow>
        );
      }}
    </Draggable>
  );
};

interface StaticCellProps<T extends {} = {}> {
  cell: Cell<T, any>;
}

const StaticCell = <TData extends {} = {}>({
  cell,
}: StaticCellProps<TData>) => {
  return <TableCell {...cell.getCellProps()}>{cell.render('Cell')}</TableCell>;
};

type DraggableCellSnapshot = {
  width: number;
  height: number;
};

interface DraggableCellProps<T extends {} = {}> {
  idx: number;
  cell: Cell<T, any>;
  draggableProvided?: DraggableProvided;
  isDragOccurring?: boolean;
}

class DraggableCell<T extends {} = {}> extends React.Component<
  DraggableCellProps<T>
> {
  tableCellRef: HTMLElement | undefined = undefined;

  getSnapshotBeforeUpdate(prevProps: DraggableCellProps<T>) {
    if (!this.props.draggableProvided || !this.tableCellRef) {
      return null;
    }
    const isDragStarting: boolean =
      this.props.isDragOccurring !== undefined &&
      this.props.isDragOccurring &&
      !prevProps.isDragOccurring;
    if (!isDragStarting) {
      return null;
    }
    const { width, height } = this.tableCellRef.getBoundingClientRect();

    const snapshot: DraggableCellSnapshot = {
      width,
      height,
    };

    return snapshot;
  }

  componentDidUpdate(
    prevProps: Readonly<DraggableCellProps<T>>,
    prevState: Readonly<{}>,
    snapshot?: DraggableCellSnapshot
  ) {
    if (!this.props.draggableProvided || !this.tableCellRef) {
      return;
    }
    if (snapshot) {
      if (+this.tableCellRef.style.width === snapshot.width) {
        return;
      }
      this.tableCellRef.style.width = `${snapshot.width}px`;
      this.tableCellRef.style.height = `${snapshot.height}px`;
      return;
    }
    if (this.props.isDragOccurring) {
      return;
    }
    if (
      this.tableCellRef.style.width === null ||
      this.tableCellRef.style.width === undefined
    ) {
      return;
    }
    this.tableCellRef.style.removeProperty('height');
    this.tableCellRef.style.removeProperty('width');
  }

  render() {
    const { idx, cell, draggableProvided } = this.props;
    if (idx === 0 && draggableProvided) {
      return (
        <TableCell
          {...cell.getCellProps()}
          ref={(elm) => (this.tableCellRef = elm as HTMLElement)}
        >
          <Stack direction="row" alignItems="center" sx={{ gap: 2 }}>
            <Box
              {...draggableProvided.dragHandleProps}
              onClick={(event) => event.stopPropagation()}
            >
              <DragHandleIcon sx={{ color: 'text.secondary', fontSize: 16 }} />
            </Box>
            <div>{cell.render('Cell')}</div>
          </Stack>
        </TableCell>
      );
    }
    return (
      <TableCell
        {...cell.getCellProps()}
        key={cell.column.id}
        ref={(elm) => (this.tableCellRef = elm as HTMLElement)}
      >
        {cell.render('Cell')}
      </TableCell>
    );
  }
}

export default StickyTable;
